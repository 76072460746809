import { IRepoErrors } from "../../errors";
import { http } from "../../http";

export class UpdateLoyaltyStatusError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: updateLoyaltyStatus");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function updateLoyaltyStatus(
  businessId: string,
  enabled: boolean
): Promise<boolean> {
  try {
    await http.put(`/api/v3/businesses/${businessId}/reward/status`, {
      enabled,
    });
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UpdateLoyaltyStatusError(error);
  }
  return true;
}
