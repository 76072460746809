



























import { t } from "@/i18n";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AtomLoading, MolModalConfirm } from "@/v2/new-design-system";
import { LoyaltyForm } from "@/v2/module/loyalty/form";
import { useHelpers } from "@/v2/composable/use-helpers";
import {
  enableReward,
  fetchReward,
  updateLoyaltyStatus,
  updateReward,
} from "@/v2/repo/loyalty";
import { report } from "@chatfood/bug-reporter";
import { Toast } from "@/design-system";
import { ILoyaltyRewards } from "@/v2/repo/loyalty/type";

const css = bemBuilder("loyalty-main");
export default defineComponent({
  name: "LoyaltyMain",
  components: {
    AtomLoading,
    MolModalConfirm,
    LoyaltyForm,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const isSaving = ref(false);
    const isLoyaltyEnabled = ref(false);
    const loyaltyProgram = ref<ILoyaltyRewards | null>(null);

    const showConfirmModal = ref(false);

    const business = computed(() => {
      return useHelpers().getCurrentBusiness(props.businessId) || {};
    });

    const businessCountry = computed(() => business.value.country);
    const businessCurrency = computed(() => business.value.currency?.code);

    const onCloseConfirmModal = () => {
      showConfirmModal.value = false;
    };

    const getLoyalty = async (): Promise<void> => {
      isLoading.value = true;
      try {
        const { isEnabled, ...rest } = await fetchReward(props.businessId);

        isLoyaltyEnabled.value = isEnabled;
        loyaltyProgram.value = rest;
      } catch (e) {
        report(e);
      } finally {
        isLoading.value = false;
      }
    };

    const onEnableLoyalty = async (value: ILoyaltyRewards): Promise<void> => {
      isSaving.value = true;

      try {
        await enableReward(props.businessId, value);
        isLoyaltyEnabled.value = true;

        new Toast().create({
          type: "success",
          text: t("module.loyalty.toast_loyalty_enabled"),
        });
      } catch (e) {
        report(e);
        new Toast().create({
          type: "error",
          text: t("module.loyalty.toast_error_enabled"),
        });
      } finally {
        isSaving.value = false;
      }
    };

    const onDisableLoyalty = async (): Promise<void> => {
      isSaving.value = true;
      try {
        try {
          await updateLoyaltyStatus(props.businessId, false);

          isLoyaltyEnabled.value = false;

          new Toast().create({
            type: "success",
            text: t("module.loyalty.toast_loyalty_disabled"),
          });
        } catch (e) {
          report(e);
          new Toast().create({
            type: "error",
            text: t("module.loyalty.toast_error_disabled"),
          });
        }
      } finally {
        showConfirmModal.value = false;
        isSaving.value = false;
      }
    };

    const onSaveLoyalty = async (value: ILoyaltyRewards): Promise<void> => {
      isSaving.value = true;
      try {
        await updateReward(props.businessId, value);
        loyaltyProgram.value = value;

        new Toast().create({
          type: "success",
          text: t("module.loyalty.toast_loyalty_saved"),
        });
      } catch (e) {
        report(e);
        new Toast().create({
          type: "error",
          text: t("module.loyalty.toast_error_saved"),
        });
      } finally {
        isSaving.value = false;
      }
    };

    onMounted(() => {
      getLoyalty();
    });

    return {
      t,
      css,
      isLoading,
      isSaving,
      isLoyaltyEnabled,
      showConfirmModal,
      businessCountry,
      businessCurrency,
      loyaltyProgram,
      onCloseConfirmModal,
      onDisableLoyalty,
      onEnableLoyalty,
      onSaveLoyalty,
    };
  },
});
