import { http } from "@/v2/repo/http";
import {
  IAxiosErrorResponse,
  RepoLoyaltyError,
} from "@/v2/repo/loyalty/error-response";
import { ILoyaltyRewards } from "@/v2/repo/loyalty/type";
import { rewardRequestAdapter } from "@/v2/repo/loyalty/utils";

export class EnableRewardError extends RepoLoyaltyError {}

export async function enableReward(
  businessId: string,
  data: ILoyaltyRewards
): Promise<boolean> {
  let reward = false;

  try {
    const response = await http.post(
      `/api/v3/businesses/${businessId}/reward/enable`,
      {
        ...rewardRequestAdapter(data),
      }
    );

    reward = Boolean(response.data);
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.message;

    const error = {
      status: response.status,
      message:
        rawErrorMessage ||
        "The request to enable a loyalty reward program failed.",
      response: response.data?.errors,
    };

    throw new EnableRewardError(error);
  }

  return reward;
}
