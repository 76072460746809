import { render, staticRenderFns } from "./LoyaltyForm.vue?vue&type=template&id=68d5513c&scoped=true&"
import script from "./LoyaltyForm.vue?vue&type=script&lang=ts&"
export * from "./LoyaltyForm.vue?vue&type=script&lang=ts&"
import style0 from "./LoyaltyForm.vue?vue&type=style&index=0&id=68d5513c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68d5513c",
  null
  
)

export default component.exports