import { path } from "ramda";
import { report } from "@chatfood/bug-reporter";

const isRuleLevel = (obj: Record<string, any>): boolean => {
  let hasKeys = false;
  for (const key in obj) {
    if (supportedRules.includes(key)) {
      hasKeys = true;
      break;
    }
  }
  return hasKeys;
};

const rules: Record<string, Function> = {
  required: (value: string | number): boolean => {
    const type = typeof value;
    return ["string", "number"].includes(type) && Boolean(`${value}`.length);
  },
  min: (value: number, limit: number): boolean => {
    return value >= limit;
  },
  max: (value: number, limit: number): boolean => {
    return value <= limit;
  },
  integer: (value: number) => {
    const num = Number(value);
    return Number.isInteger(num) && `${num}`.indexOf(".") === -1;
  },
};

const priority: Record<string, number> = {
  required: 0,
  integer: 1,
  min: 2,
  max: 2,
};

const supportedRules = Object.keys(rules);

const validationTree = (
  level: Record<string, any>,
  values: Record<string, any>,
  pathToValue: Array<string> = []
): Record<string, any> => {
  return Object.keys(level).reduce(
    (errors: Record<string, any>, key: string) => {
      const deepLevel = level[key];

      if (!isRuleLevel(deepLevel)) {
        const newErrors = validationTree(deepLevel, values, [key]);

        return Object.keys(newErrors).length
          ? { ...errors, [key]: newErrors }
          : errors;
      }

      // Validation starts here.
      const { useValidation, ...restRules } = deepLevel;

      // Make sure validation is required.
      if (useValidation && !useValidation(values)) {
        return errors;
      }

      // List of required rules for the current field.
      const currentRules = Object.keys(restRules).sort((a, b) => {
        if (priority[a] > priority[b]) return 1;
        if (priority[a] < priority[b]) return -1;
        return 0;
      });

      const value = path([...pathToValue, key], values);

      let errorMessage;
      for (const item of currentRules) {
        const validationMethod: Function = rules[item];
        const { limit, message } = restRules[item];

        if (!validationMethod(value, limit)) {
          errorMessage = message;
          break;
        }
      }
      return errorMessage ? { ...errors, [key]: errorMessage } : errors;
    },
    {}
  );
};

export const formValidationHelper = (
  config: Record<string, any>,
  values: Record<string, any>
): Record<string, any> | {} => {
  if (!config || !values) return {};

  try {
    return validationTree(config, values);
  } catch (e) {
    report(e);
    return {};
  }
};
