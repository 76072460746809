import { AxiosResponse } from "axios";

export interface IRepoLoyaltyErrorResponse {
  status: number;
  message: string;
  validationError?: Record<string, string>;
  request?: Record<string, any>;
  response?: Record<string, any>;
}

export interface IAxiosErrorResponse extends AxiosResponse {}

export class RepoLoyaltyError extends Error {
  error: IRepoLoyaltyErrorResponse;

  constructor(error: IRepoLoyaltyErrorResponse) {
    super(error.message);
    this.error = error;
    this.name = this.constructor.name;
  }
}
