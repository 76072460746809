
















































































































































import { defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { assocPath, path, type } from "ramda";
import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomSwitcher,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  OrgFormInput,
  OrgFormMoneyInput,
  OrgHeaderInfo,
} from "@/v2/new-design-system";
import { CurrencyCodeEnum } from "@/v2/enum";
import { formValidationHelper } from "@/v2/util/form/form-validation";
import { ILoyaltyRewards } from "@/v2/repo/loyalty/type";
import { LoyaltyPreview } from "@/v2/module/loyalty/preview";

const css = bemBuilder("loyalty-form");

export default defineComponent({
  name: "LoyaltyForm",
  components: {
    AtomText,
    AtomSwitcher,
    AtomButton,
    MolGuideLink,
    OrgFormInput,
    OrgFormMoneyInput,
    OrgHeaderInfo,
    LoyaltyPreview,
  },
  props: {
    isEnabled: {
      type: Boolean,
      required: true,
    },
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    savedValue: {
      type: Object as PropType<ILoyaltyRewards>,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-enable", "on-save", "on-disable"],
  setup(props, { emit }) {
    const formValues = ref({
      earningRule: {
        percentage: 0,
      },
      burningRule: {
        percentage: 0,
        maximumAmount: 0,
      },
      welcomeRule: {
        isActive: false,
        amount: 0,
      },
    });
    const errors = ref({});

    const setDefaultState = (val: ILoyaltyRewards) => {
      const { earningRule, burningRule, welcomeRule } = val || {};

      formValues.value = {
        earningRule: {
          percentage: earningRule?.percentage || 0,
        },
        burningRule: {
          percentage: burningRule?.percentage || 0,
          maximumAmount: burningRule?.maximumAmount || 0,
        },
        welcomeRule: {
          isActive: Boolean(welcomeRule.isActive),
          amount: welcomeRule.amount || 0,
        },
      };
    };

    watch(
      () => props.savedValue,
      (val) => {
        if (val) {
          setDefaultState(val);
        }
      },
      { immediate: true }
    );

    const onChange = (key: string, value: string | number) => {
      const values = { ...formValues.value };
      const path = key.split(".");

      const formattedValue =
        value && typeof value === "string" ? Number(value) : value;
      formValues.value = assocPath(path, formattedValue, values);
    };

    const formConfig = {
      earningRule: {
        percentage: {
          min: {
            limit: 5,
            message: t("module.loyalty.error_min_cashback", { min: 5 }),
          },
          max: {
            limit: 100,
            message: t("module.loyalty.error_max_cashback", { max: 100 }),
          },
          required: {
            message: t("module.loyalty.error_required_cashback"),
          },
          integer: {
            message: t("module.loyalty.error_integer_cashback"),
          },
        },
      },
      burningRule: {
        percentage: {
          min: {
            limit: 0.1,
            message: t("module.loyalty.error_min_redeemable", { min: 0 }),
          },
          max: {
            limit: 100,
            message: t("module.loyalty.error_max_redeemable", { max: 100 }),
          },
          required: {
            message: t("module.loyalty.error_required_redeemable"),
          },
          integer: {
            message: t("module.loyalty.error_integer_redeemable"),
          },
        },
        maximumAmount: {
          required: {
            message: t("module.loyalty.error_required_m_redeemable"),
          },
          min: {
            limit: 0.1,
            message: t("module.loyalty.error_min_m_redeemable", { min: 0 }),
          },
        },
      },
      welcomeRule: {
        amount: {
          useValidation({ welcomeRule }: ILoyaltyRewards): boolean {
            return welcomeRule.isActive;
          },
          required: {
            message: t("module.loyalty.error_required_welcome_bonus"),
          },
          min: {
            limit: 0.1,
            message: t("module.loyalty.error_min_welcome_bonus", { min: 0 }),
          },
        },
      },
    };

    const isFormValid = (): boolean => {
      errors.value = formValidationHelper(formConfig, formValues.value);
      return !Object.keys(errors.value).length;
    };

    const hasError = (val: string) => {
      const errorPath = val.split(".");
      return path(errorPath, errors.value);
    };

    const handleSaveChanges = () => {
      if (!isFormValid()) return;

      emit("on-save", formValues.value);
    };

    const handleEnablingLoyalty = () => {
      if (!isFormValid()) return;

      emit("on-enable", formValues.value);
    };

    const handleDisablingLoyalty = () => {
      emit("on-disable", formValues.value);
    };

    return {
      t,
      css,
      formValues,
      errors,
      hasError,
      onChange,
      handleSaveChanges,
      handleEnablingLoyalty,
      handleDisablingLoyalty,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      MolGuideLinkArticleEnum,
    };
  },
});
