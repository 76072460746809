import {
  ILoyaltyRewards,
  ILoyaltyRewardsSnakeCase,
} from "@/v2/repo/loyalty/type";

export const rewardRequestAdapter = (
  reward: ILoyaltyRewards
): ILoyaltyRewardsSnakeCase => {
  return {
    earning_rule: {
      percentage: reward.earningRule?.percentage,
    },
    burning_rule: {
      percentage: reward.burningRule?.percentage,
      maximum_amount: reward.burningRule?.maximumAmount,
    },
    welcome_rule: {
      is_active: reward.welcomeRule?.isActive,
      amount: reward.welcomeRule?.amount,
    },
  };
};
