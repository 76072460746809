import { http } from "../../http";
import {
  RepoLoyaltyError,
  IAxiosErrorResponse,
} from "@/v2/repo/loyalty/error-response";
import {
  ILoyaltyRewards,
  ILoyaltyRewardsSnakeCase,
} from "@/v2/repo/loyalty/type";

export class FetchLoyaltyRewardsError extends RepoLoyaltyError {}

export type ILoyaltyRewardsResponse = { isEnabled: boolean } & ILoyaltyRewards;

type ILoyaltyRewardsSnakeCaseResponse = {
  is_enabled: boolean;
} & ILoyaltyRewardsSnakeCase;

const loyaltyRewardsAdapter = (
  rewards?: ILoyaltyRewardsSnakeCaseResponse
): ILoyaltyRewardsResponse => ({
  isEnabled: Boolean(rewards?.is_enabled),
  earningRule: {
    percentage: rewards?.earning_rule?.percentage || 0,
  },
  burningRule: {
    percentage: rewards?.burning_rule?.percentage || 0,
    maximumAmount: rewards?.burning_rule?.maximum_amount || 0,
  },
  welcomeRule: {
    isActive: Boolean(rewards?.welcome_rule?.is_active),
    amount: rewards?.welcome_rule?.amount || 0,
  },
});

export async function fetchReward(
  businessId: string
): Promise<ILoyaltyRewardsResponse> {
  let reward = null;

  try {
    const response = await http.get(`/api/v3/businesses/${businessId}/reward`);
    reward = loyaltyRewardsAdapter(response?.data?.data);
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.message;

    const error = {
      status: response.status,
      message:
        rawErrorMessage ||
        "The request to fetch a loyalty rewards data failed.",
      response: response.data?.errors,
    };

    throw new FetchLoyaltyRewardsError(error);
  }

  return reward;
}
