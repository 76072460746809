













































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent } from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomIcon,
} from "@/v2/new-design-system";
import PreviewSvg from "./assets/loyalty-preview.svg";

const css = bemBuilder("loyalty-preview-main");

export default defineComponent({
  name: "LoyaltyPreview",
  components: { AtomText, AtomButton, PreviewSvg, AtomIcon },
  props: {
    hasLoyalty: {
      type: Boolean,
      required: true,
    },
    percentageAmount: {
      type: Number,
      required: true,
    },
  },
  emits: ["onDisable"],
  setup(props, { emit }) {
    const onDisableLoyalty = () => {
      emit("onDisable");
    };

    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      onDisableLoyalty,
    };
  },
});
